import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../pages/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: index,
    meta: { isAuth: true, title: '主页' },
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('@/pages/about/index.vue'),
  },
  {
    path: '/avaluation',
    name: 'avaluation',
    component: () => import('@/pages/avaluation/index.vue'),
    children: [
      {
        path: 'newsList',
        name: 'avaluation-newList',
        component: () => import('@/pages/avaluation/components/newsList')
      }
    ]
  },
  {
    path: '/estimated_price',
    name: 'estimatedPrice',
    component: () => import('@/pages/estimated_price/index.vue')
  },
  {
    path: '/newList',
    name: 'newList',
    component: () => import('@/pages/newList/index.vue'),
  },
  {
    path: '/newList/detaile',
    name: 'detaile',
    component: () => import('@/pages/newList/detaile.vue'),
  },
  {
    path: '/question',
    name: 'question',
    component: () => import('@/pages/question/question.vue'),
    children: [

      {
        path: 'detail',
        name: 'detail',
        component: () => import('@/pages/question/detail.vue'),
      },
    ]
  }



]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,

  routes,
  //每个页面跳转后，都跳转到页面的顶部
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})


// router.beforeEach((to, from, next) => {
//   window.dataLayer = window.dataLayer || []
//   function gtag() {
//     window.dataLayer.push(arguments)
//   }
//   if (typeof gtag !== 'undefined') {
//     gtag('config', 'YOUR-GA-MEASUREMENT-ID', {
//       'page_path': to.path
//     })
//   }
//   next()
// })

export default router
